import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss']
})
export class StarComponent implements OnInit {

  @Input() rating: number = 0;
  pointValue:number=0;
  nextRating:number=0;
  constructor() { }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void { 
    this.nextRating=Math.ceil(this.rating);
    var int_part = Math.trunc(this.rating);
    this.pointValue = Number((this.rating-int_part).toFixed(2))*100;       
  }
}
