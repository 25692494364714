import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  temp: any;
  transform(minutes: number): string {
    // Convert minutes to hours
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = Math.floor(minutes % 60);
    if (remainingMinutes <= 7) {
      remainingMinutes = 0;
    } else if (remainingMinutes >= 7 && remainingMinutes <= 22) {
      remainingMinutes = 15;
    } else if (remainingMinutes >= 22 && remainingMinutes <= 37) {
      remainingMinutes = 30;
    } else if (remainingMinutes >= 37 && remainingMinutes <= 52) {
      remainingMinutes = 45;
    } else if (remainingMinutes >= 52 && remainingMinutes <= 60) {
      remainingMinutes = 0;
      hours = hours + 1;
    }
    let formattedTime;

    if (hours === 1 && remainingMinutes === 0) {
      formattedTime = `${hours} hr`;
    } else if (hours === 0) {
      formattedTime = `${remainingMinutes} min`;
    } else if (hours === 1 && remainingMinutes > 0) {
      formattedTime = `${hours}:${remainingMinutes} hr`;
    }
    else if (hours > 1 && remainingMinutes == 0) {
      formattedTime = `${hours} hrs`;
    } else {
      formattedTime = `${hours}:${remainingMinutes} hrs`;
    }
    return formattedTime;
  }
}
