import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './common.service';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UtilityServiceService {

  constructor(private router: Router, private commonService: CommonService) { }

  public showSuccessMessage(message: string) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      text: message,
      confirmButtonText: "Close",
      title: 'Success',
      showConfirmButton: false,
      timer: 1500
    })

  }


  public showFailMessage(message: string) {
    Swal.fire({
      title: "Failure",
      text: message,
      confirmButtonText: "Close",
      icon: "warning",
      customClass: {
        container: 'my-swal'
      }
    });
  }

  public showConfirmationMessageWithRedirectURL(
    subject: string,
    message: string,
    type: string,
    goToURL: string
  ): any {
    return Swal.fire({
      title: subject,
      text: message,
      icon:
        type == "warning"
          ? "warning"
          : type == "success"
            ? "success"
            : type == "info"
              ? "info"
              : "question",
      showCancelButton: false,
      confirmButtonText: "Ok",
      cancelButtonColor: "#3f6ad8",
    }).then((result) => {
      if (result.value) {
        this.router.navigate([goToURL]);
      }
    });
  }

  public SomethingWentWrong() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    })
  }

  public AreYouSure(message: string): any {
    return Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes"
    })
  }

  public showInfo() {
    return Swal.fire({
      title: 'Info',
      icon: 'info',
      html:
        'Link for payment has been sent to your mail, ' +
        'Please wait...',
      showCloseButton: true,
      focusConfirm: false,
    });
  }

  /*  public ConfirmAgain(message:string) {
     Swal.fire({
       title: 'Are you sure?',
       text: message,
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, Im sure!'
     }).then((result) => {
       if (result.isConfirmed) {
         this.router.navigate(['/main/users']);
         Swal.fire(
           'Done!',
           'Lead is now user.',
           'success'
         )
       } 
     })
   } */

  // checkSoldOutItinerary(tour_uuid): Observable<boolean> {
  //   return this.commonService.getStationsByTour(tour_uuid).pipe(
  //     switchMap((res: any) => {
  //       if (res.code === 'SUCCESS' && res.message === 'STATIONS_LIST') {
  //         if (res.data) {
  //           const observables = res.data.map((ele) => {
  //             const payload = {
  //               station_uuid: ele.station_uuid,
  //               tour_uuid: tour_uuid,
  //               start_time: new Date(),
  //             };
  //             return this.commonService.getAvailableSlots(payload);
  //           });
  //           return forkJoin(observables).pipe(
  //             map((responses: any[]) => {
  //               return responses.some((res) => {
  //                 if (res.message === 'NO_SLOTS_AVAILABLE' || res.message === 'NO_CYCLES_AT_STATION' || Object.keys(res.data).length === 0) {
  //                   return true;
  //                 } else {
  //                   return false;
  //                 }
  //               });
  //             }),
  //             catchError(() => of(false))
  //           );
  //         } else {
  //           return of(false);
  //         }
  //       }
  //        else {
  //         return of(false);
  //       }
  //     })
  //   );
  // }
}
