import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkPrefix'
})
export class LinkPrefixPipe implements PipeTransform {
    IMG_BASE_URL = "https://strode-media.s3.ap-south-1.amazonaws.com/strodeexperiences-live-media";
    transform(value: string): string {
        return this.IMG_BASE_URL + value;
    }
}
