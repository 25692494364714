<div class="row footer justify-content-center  p-3">
        <div class="col-lg-12 text-center">
                <a [routerLink]="['/']" class="text-decoration-none">
                        <img [src]="'/element/logo.png' | linkPrefix" class="logo my-3 cursor-pointer" alt="">
                        <h3 class="TTRegular-font text-white fsize-18">Strode Experiences</h3>
                </a>
                <h3 class="futura-book-font fsize-16 mt-4  text-dark"><i class="fa fa-phone text-white"></i><a
                                href="tel:+1800 2678 763" class="text-decoration-none text-white"> 1800 2678 763</a>
                </h3>
        </div>
        <div class="col-lg-8 my-3 ps-4 ps-md-0 footer-container">
                <div class="row py-1 px-md-3  justify-content-center ">
                        <div class="col-lg-3 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">What is Strode</h3>
                                <div class="my-4 text-start ">
                                        <li class="my-2"><a [routerLink]="['/']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Home</a>
                                        </li>
                                        <li class="my-2"><a [routerLink]="['/what-is-strode/how-we-work']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">How
                                                        We Work</a>
                                        </li>
                                        <li class="my-2"><a [routerLink]="['/what-is-strode/self-guided-trips']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Self
                                                        Guided Trips</a></li>
                                        <li class="my-2"><a [routerLink]="['/what-is-strode/what-we-do']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">What
                                                        We Do</a></li>
                                        <li class="my-2"><a [routerLink]="['/what-is-strode/sustainability']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Sustainability</a>
                                        </li>
                                </div>
                        </div>
                        <div class="col-lg-3 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">Destinations</h3>
                                <div class="my-4 text-start ">
                                        <li class="my-2"><a (click)="redirectToCity('lonavala')"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Lonavala, IN</a>
                                        </li>
                                        <li class="my-2"><a (click)="redirectToCity('jaipur')"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Jaipur, IN</a>
                                        </li>
                                        <li class="my-2"><a (click)="redirectToCity('udaipur')"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Udaipur, IN</a></li>
                                        <li class="my-2"><a (click)="redirectToCity('amsterdam')"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Amsterdam, NL</a></li>
                                </div>
                        </div>
                        <div class="col-lg-3  col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">All The Info</h3>
                                <div class="my-4 text-start ">
                                        <li class="my-2"><a [routerLink]="['/all-the-info/our-travel-style']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Our
                                                        Travel Styles</a></li>
                                        <li class="my-2"><a [routerLink]="['/all-the-info/local-hosts']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Local Hosts</a>
                                        </li>
                                        <li class="my-2"><a [routerLink]="['/all-the-info/hospitality-partners']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                        Hospitality Partners</a></li>
                                        <li class="my-2"><a [routerLink]="['/all-the-info/all-faqs']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">All
                                                        FAQs</a></li>
                                        <li class="my-2"><a [routerLink]="['/all-the-info/join-our-team']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Join
                                                        Our Team</a>
                                        </li>
                                </div>
                        </div>
                        <div class="col-lg-2 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">Helpful Links</h3>
                                <div class="my-4 text-start ">
                                        <li class="my-2"><a [routerLink]="['/other/contact-us']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Contact
                                                        Us</a></li>
                                        <li class="my-2"><a [routerLink]="['/policy/booking-policy']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Booking
                                                        Policy</a></li>
                                        <li class="my-2"><a [routerLink]="['/policy/privacy-policy']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Privacy
                                                        Policy</a>
                                        </li>
                                        <li class="my-2"><a [routerLink]="['/policy/cookie-policy']"
                                                        class="text-white text-decoration-none TTRegular-font  fsize-16">Cookie
                                                        Policy</a></li>
                                        <li class="my-2"><a [routerLink]="['/policy/terms-of-use']"
                                                        class="text-white text-decoration-none TTRegular-font   fsize-16">Terms
                                                        of Use</a></li>
                                </div>
                        </div>
                </div>
        </div>
        <div class="col-lg-12 p-0 text-center">
                <div class="social-media text-center pb-3 bottom-line">
                        <a href="https://www.facebook.com/strodeexperience?mibextid=eBUYbo" target="_blank"><i
                                        class="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="https://www.twitter.com/strodebike/" target="_blank"><img
                                        [src]="'/element/twitter.svg' | linkPrefix" alt=""></a>
                        <a href="https://www.linkedin.com/company/strodebike/" target="_blank"><i class="fa fa-linkedin"
                                        aria-hidden="true"></i></a>
                        <a href="https://www.instagram.com/strodeexperiences/" target="_blank"><i
                                        class="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="https://www.threads.net/@strodeexperiences" target="_blank"><img
                                        [src]="'/element/thread.svg' | linkPrefix" alt=""></a>
                        <p class="TTRegular-font fsize-14 my-3 text-white d-none d-md-block">Moving Strodesters Inc, All
                                rights reserved. </p>
                </div>
                <p class="TTRegular-font fsize-14 my-3 text-white d-md-none d-block">Moving Strodesters Inc, All rights
                        reserved. </p>
        </div>
</div>