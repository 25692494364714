import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';

@Pipe({
    name: 'currencyConverter',
    pure: false
})
export class CurrencyConverterPipe implements PipeTransform {
    private decimalPipe = new DecimalPipe('en-US');
    constructor(private cdr: ChangeDetectorRef) { }
    transform(price: number) {
        let currencyRate = JSON.parse(sessionStorage.getItem('currencyType'));
        if (currencyRate != null) {
            let Cvalue = currencyRate.Cvalue;
            if (Cvalue > 0 && price > 0) {
                let convertedPrice = Cvalue * price;
                if (convertedPrice % 1 !== 0) {
                    convertedPrice = Number(convertedPrice.toFixed(2));
                }
                return `${currencyRate.symbol}${this.decimalPipe.transform(convertedPrice)}`
            } else {
                return `${currencyRate.symbol}${this.decimalPipe.transform(price)}`;
            }
        } else {
            let obj = { type: 'INR', Cvalue: 1, symbol: '&#8377;' };
            sessionStorage.setItem('currencyType', JSON.stringify(obj));
            return `${currencyRate.symbol}${this.decimalPipe.transform(price)}`;
        }
    }
}
