import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/shared/services/common.service';
import { UtilityServiceService } from 'src/shared/services/utility-service.service';

@Component({
  selector: 'app-check-payment-status',
  templateUrl: './check-payment-status.component.html'
})
export class CheckPaymentStatusComponent implements OnInit {
  showLoader = false;
  constructor(
    private messageService: MessageService,
    private router: Router,
    private commonService: CommonService,
    private Location: LocationStrategy,
    private route: ActivatedRoute,
  ) {
    history.pushState(null, null, window.location.href);
    this.Location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      let orderId = params['orderId'];
      let bookingUUID = params['bookingUUID'];
      let totalTime = 2 * 60 * 1000;
      let intervalTime = 5000;
      let timeoutId: any;
      const checkPaymentStatus = () => {
        this.showLoader = true;
        this.commonService.getPaymentStatus(bookingUUID).subscribe((res: any) => {
          if (res.code == 'SUCCESS' && res.message == 'RAZORPAY_ORDER_STATUS_SUCCESS') {
            clearTimeout(timeoutId);
            this.router.navigate(['/booking/app-booking-confirmation'],
              {
                queryParams: {
                  bookingUUID: bookingUUID,
                },
              }
            );
          } else if (res.code == 'FAILED' && res.message == 'RAZORPAY_PAYMENT_NOT_RECEIVED') {
            clearTimeout(timeoutId);
            this.router.navigate(['/booking/payment-fail']);
          } else {
            this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
          }
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        })
        if (totalTime > 0) {
          totalTime -= intervalTime;
          timeoutId = setTimeout(checkPaymentStatus, intervalTime);
        }
      }
      checkPaymentStatus();
    });
  }
}