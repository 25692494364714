import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { proceedBookingComponent } from './proceed-booking/proceed-booking.component';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import { CheckPaymentStatusComponent } from './check-payment-status/check-payment-status.component';

const routes: Routes = [
  { path: 'app-proceed-booking', component: proceedBookingComponent },
  { path: 'payment-fail', component: PaymentFailComponent },
  { path: 'app-booking-confirmation', component: BookingConfirmationComponent },
  { path: 'check-payment-status/:orderId/:bookingUUID', component: CheckPaymentStatusComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingRoutingModule { }
