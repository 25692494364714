<app-web-header></app-web-header>
<div class="self-guided-trips overflow-hidden position-absolute">
    <div id="carouselExampleInterval" class="carousel parallax slide carousel-fade" data-bs-ride="carousel"
        data-interval="4000">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleInterval" [ngClass]="" data-bs-slide-to="0"
                class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active position-relative">
                <img [src]="'/home/carousel-1.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h5 class="fsize-18 TTRegular-font">DISCOVER</h5>
                    <br>
                    <p class="titlefont font-50 text-white my-2 showtext">local experiences </p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                EXPERIENCES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item position-relative">
                <img [src]="'/home/caousel-2.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h5 class="fsize-18 TTRegular-font">ACTIVITIES </h5>
                    <br>
                    <p class="titlefont font-50  text-white my-2 showtext">beyond the ordinary</p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                ADVENTURES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item position-relative">
                <img [src]="'/New Images/HomepageSlider3.webp' | linkPrefix" alt="...">
                <div class="carousel-caption ">
                    <h5 class="fsize-18 TTRegular-font">PLACES</h5>
                    <br>
                    <p class="titlefont font-50 text-white my-2 showtext">off the beaten track</p>
                    <div class="subtitlebox position-absolute w-100">
                        <div class="container">
                            <button class="pink-orangebtn text-white common-button " (click)="goToTabs()">ALL
                                DESTINATIONS</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev opacity-0" type="button" data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next opacity-0" type="button" data-bs-target="#carouselExampleInterval"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="row  learn-to-live position-relative  my-md-4 pt-md-5 justify-content-center ">
        <div class="col-lg-6 text-center  ">
            <img [src]="'/home/pink-img.webp' | linkPrefix" class="position-absolute pink-line d-block d-md-none"
                alt="">
            <img [src]="'/home/blue-line.webp' | linkPrefix" class="position-absolute blue-line  d-block d-md-none"
                alt="">
            <img [src]="'/home/logo_svg.png' | linkPrefix" class="logo d-none d-md-inline showtext" alt="">
            <img [src]="'/home/logo_svg.png' | linkPrefix" class="logo d-inline d-md-none showtext" alt="">
            <h3 class="font-40 TTRegular-font light-black" data-aos="zoom-in" data-aos-duration="800"><b>STRODE</b>
            </h3>
            <p class="TTRegular-font fsize-18 light-black">LEARN TO LIVE AGAIN</p>
            <div class="py-5 px-4 px-md-0">
                <p class="futura-book-font fsize-16 line-height2 light-black">Travel isn't rocket science nor secret
                    technology. Today, listicles on the internet tells you how to explore when you're on a holiday, but
                    they rarely ask how you want to feel. For us, every experience we offer begins with an emotion. What
                    moves you to tears? Who makes you laugh? What compels you to think?</p>
                <p class="futura-book-font fsize-16 line-height2 light-black my-4">We are as concerned with the highest
                    drama as we are with the smallest moments while curating these local experiences. We don’t just want
                    you to go there and do that. We want you to feel it, live it and be gently transformed by travel.
                    Every experience we offer is either personally hosted by our network of young and super friendly
                    local hosts or self guided using our smart, traveller-friendly e-bikes.</p>
                <p class="futura-book-font fsize-16 line-height2 light-black my-4">Choose from our thoughtfully tailored
                    tours and trails, activities and adventures. A planet-loving, adventure-seeking community of
                    Strodesters is geared up to welcome you.<br class="d-none d-md-block"> Hop on and discover the world
                    on your own terms.</p>
            </div>
        </div>
        <div class="position-absolute dotted-linecover  w-100 h-100"></div>
        <img [src]="'/element/dottedline1.svg' | linkPrefix" class="position-absolute dotted-line  d-none d-md-block"
            alt="">
        <img [src]="'/element/bike.svg' | linkPrefix" class="position-absolute bike  d-none d-md-block" alt="">
    </div>
    <div class="row justify-content-center our-style" id="destination">
        <div class="text-center">
            <p class="TTRegular-font fsize-18 light-black">ADVENTURES DONE DIFFERENTLY</p>
            <h3 class="font-50 titlefont mb-md-4" data-aos="zoom-in" data-aos-duration="800">our destinations</h3>
        </div>
        <div class="col-lg-8 mt-md-5 d-none" id="category">
            <div class="d-flex justify-content-md-center category-list">
                <div *ngFor="let cat of category" [ngClass]="activeCategory==cat?'active-category':''"
                    class="px-md-4 px-3 py-4  text-center category-box" (click)="changeCategory(cat)">
                    <h3 class="TTRegular-font fsize-16">{{cat}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-8 mt-5 d-none" id="categoryformobile">
            <div class="d-flex justify-content-md-center category-list">
                <div *ngFor="let cat of category" [ngClass]="activeCategory==cat?'active-category':''"
                    class="px-md-4   text-center category-box" (click)="changeCategory(cat)">
                    <h3 class="TTRegular-font fsize-16">{{cat}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-10 d-none d-md-block my-2 destination" data-aos="zoom-out-up"
            *ngIf="activeCategory=='DESTINATIONS'">
            <div class="row">
                <div class="col-lg-6 p-3 text-center position-relative cursor-pointer" *ngFor="let ele of destinations"
                    (click)="redirectToCity(ele.city.toLowerCase())">
                    <img [src]="ele.img" alt="">
                    <h3 class="TTRegular-font fsize-20 fw-bold position-absolute text-white title">
                        {{ele.city.toUpperCase()}}</h3>
                </div>
            </div>
        </div>
        <div class="col-lg-10 d-md-none d-block my-2 destination" *ngIf="activeCategory=='DESTINATIONS'">
            <div class="d-flex flex-nowrap justify-content-start overflow-auto hidescrollbar"
                style=" white-space: nowrap;">
                <div class="col-10 ps-2 text-center position-relative" *ngFor="let ele of destinations;let i=index;"
                    (click)="redirectToCity(ele.city.toLowerCase())">
                    <img [src]="ele.img" alt="">
                    <h3 class="TTRegular-font fsize-20 fw-bold position-absolute text-white title">
                        {{ele.city.toUpperCase()}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-md-5 pt-md-5 mt-5 mt-md-0 pt-2 our-ethos justify-content-center">
        <div class="text-center mt-md-5">
            <p class="TTRegular-font fsize-18 light-black opacity9">EVERYTHING REVOLVES AROUND </p>
            <h3 class="font-50 titlefont" data-aos="zoom-in" data-aos-duration="800">our ethos</h3>
        </div>
        <div class="col-lg-8 my-md-3 my-4 py-4 d-flex flex-md-row flex-column justify-content-evenly position-relative"
            data-aos="zoom-in-up">
            <img [src]="'/home/trangle.webp' | linkPrefix" class="triangle-img d-none d-md-inline position-relative"
                alt="">
            <div class="px-1 text-center mt-3 mt-md-0">
                <img [src]="'/home/onefor.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">One for Everyone</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Crafting
                    experiences with a <br class="d-none d-md-block"> unique touch for the varied tastes <br
                        class="d-none d-md-block"> you may have. </p>
            </div>
            <div>
            </div>
            <div class="px-1 text-center mt-5 mt-md-0">
                <img [src]="'/home/local-connection.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">Local Connections</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Make friends with
                    our hosts who tell <br class="d-none d-md-block"> you those local facts, stories and <br
                        class="d-none d-md-block"> share laughs.</p>
            </div>
            <div>
            </div>
            <div class="px-1 text-center mt-5 mt-md-0">
                <img [src]="'/home/travel-responsibl.webp' | linkPrefix" class="w-100 cover" alt="">
                <h3 class="fsize-20 my-4 py-2 TTRegular-font light-black " data-aos="fade-up">Travel Responsibly</h3>
                <p class="futura-book-font fsize-16 light-black my-4 px-md-0 px-3" data-aos="fade-up">Cut the carbon
                    footprint when you <br class="d-none d-md-block"> ride on our specially designed e-bikes. <br
                        class="d-none d-md-block"> Give it a shot.</p>
            </div>
            <div>
                <img [src]="'/home/trangle.webp' | linkPrefix" class="triangle-img d-none d-md-inline position-relative"
                    alt="">
            </div>
        </div>
    </div>
    <div class="row justify-content-center position-relative our-wave">
        <div class="text-center">
            <p class="TTRegular-font fsize-18 light-black opacity9">WHERE WORDS MATTER</p>
            <h3 class="font-50 titlefont  mb-md-4" data-aos="zoom-in" data-aos-duration="800">our wave</h3>
        </div>
        <div class="col-lg-7 my-4 pb-4 flex-md-row flex-column d-none  d-md-flex   pe-md-5 justify-content-between">
            <div class="col-lg-5  position-relative ">
                <img [src]="'/home/wave-1.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bg-pink text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18 "> LIFE AT 25KMPH</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Observe more, <br>Immerse more</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white orange-btn"
                        (click)="loadBlogDetail('Museums in Jaipur')">READ UP</button>
                    <br>
                </div>
            </div>
            <div class="col-lg-5 position-relative top-40 ">
                <img [src]="'/home/wave-2.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bg-violet text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18 text-white"> SUMMERS WITH US</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Summertime Stroding in <br> Amsterdam</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white violet-btn"
                        (click)="loadBlogDetail('Lakes of Udaipur – Your Ultimate Guide to exploring the Venice of the East or West')">READ
                        UP</button>
                    <br>
                </div>
            </div>
            <img [src]="'/home/smalllines.webp' | linkPrefix"
                class="position-absolute small-lines-left small-lines showtext d-none d-md-inline" alt="">
            <img [src]="'/home/smalllines.webp' | linkPrefix"
                class="position-absolute small-lines-right small-lines showtext d-none d-md-inline" alt="">
        </div>
        <div class="col-lg-7  my-5 pb-4 flex-md-row flex-column d-none  d-md-flex    pe-md-5  justify-content-between">
            <div class="col-lg-5 position-relative top-20">
                <img [src]="'/home/wave-3.webp' | linkPrefix" class="cover" alt="">
                <div class="py-3 px-3 tealgreen text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18 text-white">ADVENTURES WITH US</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">How to plan your next adventure <br> with
                        Strode</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white teelgreen-btn"
                        (click)="loadBlogDetail('Country Roads, Take My Heart Four Reasons for the Rise of Rural Travel')">READ
                        UP</button>
                    <br>
                </div>
            </div>
            <div class="col-lg-5 position-relative top-40">
                <img [src]="'/home/wave-4.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bgorange text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18 text-white"> BE BETTER, TOGETHER</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Let's make a difference when <br> you travel
                        with Strode </p>
                    <button class="common-button small-button  readup-btn mb-2 text-white pink-btn"
                        (click)="loadBlogDetail('Revengers, Assemble! The Comeback Travel Trend')">READ UP</button>
                    <br>
                </div>
            </div>
        </div>
        <div class="col-lg-7 mt-5 p-0 hidescrollbar mh-500  d-md-none d-flex  flex-nowrap justify-content-start overflow-auto"
            style=" white-space: nowrap;">
            <div class="col-10 p-1 position-relative ">
                <img [src]="'/home/wave-1.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bg-pink text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18"> LIFE AT 25KMPH</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Observe more, <br>Immerse more</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white orange-btn"
                        (click)="loadBlogDetail('Bleisure Travel Trends - A SWOT Analysis by Jay Rodriguez')">READ
                        UP</button>
                    <br>
                </div>
            </div>
            <div class="col-10 p-1 position-relative top-40 ">
                <img [src]="'/home/wave-2.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bg-violet text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18 "> SUMMERS WITH US</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Summertime Stroding in <br> Amsterdam</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white violet-btn"
                        (click)="loadBlogDetail('FOUR REASONS YOU SHOULD BOOK A STRODE TOUR ON E-BIKES')">READ
                        UP</button>
                    <br>
                </div>
            </div>
            <div class="col-10 p-1 position-relative top-20">
                <img [src]="'/home/wave-3.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 tealgreen text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18">ADVENTURES WITH US</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">How to plan your next adventure <br> with
                        Strode</p>
                    <button class="common-button small-button  readup-btn mb-2 text-white teelgreen-btn"
                        (click)="loadBlogDetail('Welcoming Travellers with a Conscience - Hospitality in 2023 and Beyond')">READ
                        UP</button>
                    <br>
                </div>
            </div>
            <div class="col-10 p-1 position-relative top-40">
                <img [src]="'/home/wave-4.webp' | linkPrefix" alt="">
                <div class="py-3 px-3 bgorange text-white sideBox position-absolute showtext">
                    <h3 class="TTRegular-font fsize-18"> BE BETTER, TOGETHER</h3>
                    <p class="fsize-16 futura-book-font my-3 text-white">Let's make a difference when <br> you travel
                        with Strode </p>
                    <button class="common-button small-button  readup-btn mb-2 text-white pink-btn"
                        (click)="loadBlogDetail('TISS - Take it Slow, Stupid')">READ UP</button>
                    <br>
                </div>
            </div>
            <img [src]="'/home/smalllines.webp' | linkPrefix"
                class="position-absolute small-lines-left small-lines showtext d-none d-md-inline" alt="">
            <img [src]="'/home/smalllines.webp' | linkPrefix"
                class="position-absolute small-lines-right small-lines showtext d-none d-md-inline" alt="">
        </div>
    </div>
    <div class="row justify-content-center position-relative weare-different mt-md-5 pt-md-4">
        <div class="text-center py-md-4 my-md-2">
            <p class="TTRegular-font fsize-18 light-black ">WE ARE DIFFERENT</p>
            <h3 class="font-50 titlefont" data-aos="zoom-in" data-aos-duration="800">know us</h3>
        </div>
        <div class="col-lg-8 mt-5 mt-md-0">
            <div class="row justify-content-end px-md-4 ">
                <div class="col-md-6 text-center d-block d-md-none">
                    <img [src]="'/home/weare.webp' | linkPrefix" style="width: 90%;" class="cover" alt="">
                </div>
                <div class="col-lg-10 d-flex mt-2 justify-content-between flex-md-row flex-column round-right box bgorange"
                    data-aos="zoom-out-right">
                    <div class="col-md-5">
                        <img [src]="'/home/weare.webp' | linkPrefix"
                            class="left-img d-none d-md-block cover position-relative" alt="">
                    </div>
                    <div class="col-md-7 my-auto text-white text-center">
                        <h3 class="TTRegular-font fsize-20">WE ARE</h3>
                        <br>
                        <p class="futura-book-font fsize-16 text-white">a forward-thinking travel company <br> born out
                            of a passion for doing <br> 'things differently'.</p>
                        <p class="futura-book-font fsize-16 my-2 text-white">Unlike the typical city tour, our <br>
                            collection of offbeat locations & <br> handpicked experiences goes far <br> beyond the
                            city's popular places <br> & the commercialized activities you'll <br> find on the internet.
                        </p>
                    </div>
                </div>
                <div class="position-absolute fill-wave-right  single-wave bg-white hide" style="z-index: -1;"></div>
                <img [src]="'/home/fill-wave.webp' | linkPrefix"
                    class="position-absolute hide img-fit-contain  fill-wave-right" alt="">
            </div>
            <div class="row justify-content-start px-md-4 mb-8">
                <div class="col-md-6 text-center d-block d-md-none">
                    <img [src]="'/home/we-serve.webp' | linkPrefix" style="width: 90%;" class="cover" alt="">
                </div>
                <div class="col-lg-10 my-5 d-flex justify-content-between flex-md-row flex-column round-left violet-box box bg-violet"
                    data-aos="zoom-out-left">
                    <div class="col-md-7 my-auto text-white text-center">
                        <h3 class="TTRegular-font fsize-20">WE SERVE</h3>
                        <br>
                        <p class="futura-book-font fsize-16 text-white">local experiences curated <br> by our young
                            travel experts. <br> You'll surely love them.</p>
                        <p class="futura-book-font fsize-16 my-2 text-white">Step inside those <br> local places and
                            communities <br> that are hard to find <br> on your own and create <br> lasting connections
                            <br> with treasured memories.</p>
                    </div>
                    <div class="col-md-5 d-none d-md-block">
                        <img [src]="'/home/we-serve.webp' | linkPrefix" class="right-img   position-relative cover"
                            alt="">
                    </div>
                </div>
                <div class="position-absolute fill-wave-right empty-wave-left hide single1-wave bg-white"
                    style="z-index: -1;"></div>
                <img [src]="'/home/empty-wave.webp' | linkPrefix"
                    class="position-absolute img-fit-contain fill-wave-right hide cover  empty-wave-left" alt="">
            </div>
        </div>
    </div>
    <div class="remove-space">
        <div class="row justify-content-center position-relative  reach-us my-5 py-2">
            <div class="text-center">
                <p class="TTRegular-font fsize-18 light-black">CAN'T FIND IT</p>
                <h3 class="font-50 titlefont" data-aos="zoom-in" data-aos-duration="800">reach us</h3>
            </div>
            <div class="text-center mt-4 px-md-0 px-5   ">
                <p class="futura-book-font fsize-16 mb-4 mb-md-0">Don't worry. Just because it's not here, doesn't mean
                    we can't do. <br> Call and tell us what you're looking for. </p>
                <p class="TTRegular-font fsize-18 mt-md-4">WE'LL MAKE IT HAPPEN.</p>
            </div>
            <img [src]="'/home/foot-1.webp' | linkPrefix" class="position-absolute showtext foot foot-left  px-0 "
                alt="">
            <img [src]="'/home/foot2.webp' | linkPrefix" class="position-absolute showtext foot foot-right  px-0 "
                alt="">
        </div>
        <div class="row justify-content-center position-relative  get-inspired mt-5 pt-5">
            <div class="col-lg-6 mt-3 pt-2 px-0 py-0">
                <img [src]="'/home/get-inspired.webp' | linkPrefix" class="w-100 h-100 border-right cover" alt="">
            </div>
            <div class="col-lg-6 bglinear-lightorg  mt-4 pt-3 text-center pe-4">
                <div class="text-center mt-4">
                    <p class="TTRegular-font fsize-18 light-black ">JOIN OUR MAILING LIST</p>
                    <h3 class="font-50 titlefont mt-md-3" data-aos="zoom-in" data-aos-duration="800">get inspired</h3>
                </div>
                <p class="futura-book-font light-black fsize-16 my-4 ">Receive destination guides & inspiration straight
                    to your inbox</p>
                <div class="row justify-content-center ps-4 pe-2 pe-md-0 ps-md-0 position-relative my-5 py-4">
                    <div class="col-lg-7  text-start px-3  position-relative">
                        <form #f="ngForm" (ngSubmit)="joinUs(f)">
                            <div>
                                <label class="futura-book-font fsize-14"> Name</label>
                                <input type="text" #name="ngModel" name="name" required minlength="5" maxlength="50"
                                    [(ngModel)]="signUpObj.name" class="w-100 mt-1 rounded p-1 px-3"
                                    [ngClass]="{'invalid-input':(name.invalid && f.submitted)|| name.invalid && name.touched}">
                            </div>
                            <div class="mt-3">
                                <label class="futura-book-font fsize-14"> Email</label>
                                <input type="text" required maxlength="50" #email="ngModel"
                                    [(ngModel)]="signUpObj.email" name="email" class="w-100 mt-1 rounded p-1 px-3"
                                    [pattern]="emailPattern"
                                    [ngClass]="{'invalid-input':(email.invalid && f.submitted)|| (email.invalid && email.touched)}">
                            </div>
                            <div class="text-center my-4 py-2">
                                <button
                                    class="common-button small-button text-uppercase   round-30  text-white teelgreen-btn joinus-btn">Join
                                    us</button>
                            </div>
                            <p class="TTRegular-font fsize-18 light-black text-center thank-you mt-4"
                                [ngClass]="signUpDone?'visible':'invisible'"> {{signUpResponses}}</p>
                        </form>
                    </div>
                    <img [src]="'/home/two-lines.webp' | linkPrefix"
                        class="position-absolute d-none d-md-block two-lines " alt="">
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<p-toast></p-toast>