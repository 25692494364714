import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {
  constructor(private router: Router, private cdr: ChangeDetectorRef, private messageService: MessageService, private commonService: CommonService) { }

  exploreText = "EXPLORE";
  isFixedHeader: boolean = false;
  isScrollingDown: boolean = false;
  previousScrollPosition = 0;
  accordionActiveState = [false, false, false, false];
  currency = [{}];
  selectedCurrency: any;
  path = ''

  ngOnInit(): void {
    this.loadCurrency();
    this.loadGlobalCurrency();
    this.path = this.router.url;
  }

  @HostListener('window:scroll', [])

  onScroll(): void {
    const scrollPosition = window.scrollY;
    this.isFixedHeader = scrollPosition > 0 && scrollPosition < 400;
    this.isScrollingDown = window.scrollY > this.previousScrollPosition;
    this.previousScrollPosition = window.scrollY;
    if (!this.isScrollingDown) {
      this.isFixedHeader = true;
    }
    if (scrollPosition == 0) {
      this.isFixedHeader = false;
    }
  }

  openMenu() {
    document.getElementById('navlist').classList.toggle('open');
    document.getElementById('menulink').classList.toggle('act');
    document.getElementById('mobile-menu').classList.toggle('show');
  }
  redirectToCity(city) {
    this.router.navigateByUrl(`/things-to-do-in-${city}`);
  }

  exploremore(e) {
    this.commonService.blurElement(e);
    this.router.navigate(['/other/explore']);
  }

  toggleAccordion(index: number) {
    this.accordionActiveState = [false, false, false, false];
    this.accordionActiveState[index] = !this.accordionActiveState[index];
  }
  loadCurrency() {
    this.currency = [
      { type: 'INR', Cvalue: 1, symbol: '&#8377;' },
      { type: 'USD', Cvalue: 1, symbol: '&#36;' },
      { type: 'EUR', Cvalue: 1, symbol: '&#8364;' }
    ];
  }

  changeCurrency(newCurrency: any) {
    this.selectedCurrency = newCurrency;
    localStorage.setItem('currencyType', JSON.stringify(this.selectedCurrency));
    this.loadGlobalCurrency();
    this.addCurrencyValue();
  }
  loadGlobalCurrency() {
    const storedCurrency = JSON.parse(localStorage.getItem('currencyType'))
    if (!storedCurrency) {
      this.commonService.getCurrencyRate('INR').subscribe((res: any) => {
        if (res) {
          let currencyrate = res.conversion_rates.hasOwnProperty(this.selectedCurrency.type)
            ? res.conversion_rates[this.selectedCurrency.type]
            : 1;
          this.selectedCurrency.Cvalue = currencyrate;
          localStorage.setItem('currencyType', JSON.stringify(this.selectedCurrency));
          this.cdr.detectChanges();
        } else {
          this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
        }
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
      });
    } else {
      this.selectedCurrency = storedCurrency;
    }
  }
  addCurrencyValue() {
    this.commonService.getCurrencyRate('INR').subscribe((res: any) => {
      if (res) {
        let currencyValue = res.conversion_rates.hasOwnProperty(this.selectedCurrency.type)
          ? res.conversion_rates[this.selectedCurrency.type]
          : 1;
        this.selectedCurrency.Cvalue = currencyValue;
        sessionStorage.setItem('currencyType', JSON.stringify(this.selectedCurrency));
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }

}
