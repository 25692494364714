<p-toast></p-toast>
<div class="container-fluid textfont mt-md-5 p-0">
    <div class="row justify-content-center g-0">
        <div class="col-lg-10">
            <div class="row topSection">
                <div class="text-white row tourimgbox p-3"
                    style="background: linear-gradient(rgba(248, 248, 248, 0) 34.58%, rgb(3 76 82) 86.11%),url('https://strodeexplore.s3.ap-south-1.amazonaws.com{{proceedBookingObj.heroImages[0].uuid}}')">
                    <div class="col-12 text-center tournamebox">
                        <p class="tourname text-center titlefont text-white text-lowercase mt-1">{{
                            proceedBookingObj?.tourRes?.name || "" }}</p>
                    </div>
                    <div class="col-md-6 mt-5 pt-5 ps-md-3 TTRegular-font tourdetailbox">
                        <span class="mt-3 tourdetail fsize-14 text-white"> {{ proceedBookingObj?.tourRes?.location ||
                            proceedBookingObj?.tourRes?.city}}, </span>
                        <span class="fsize-14 text-white"> {{ proceedBookingObj?.tourRes?.navigation?.duration
                            |minutesToHours }} </span>
                        <span class="px-md-3 px-1 text-white">|</span> <span
                            [innerHTML]=" proceedBookingObj?.join?.bookingData?.hs_cost | currencyConverter"
                            class="fsize-14 text-white"> / Guest</span>
                        <span class="px-md-3 px-1 text-white">|</span>
                        <span class="tourdetail fsize-14 text-white"><i class="fa fa-star text-white"
                                aria-hidden="true"></i> {{proceedBookingObj?.tourRes?.strode_rating}} ({{
                            proceedBookingObj?.reviews?.length }} reviews)</span>
                    </div>
                    <div class="col-md-6 TTRegular-font mt-5 ps-4 ms-1 ms-md-0 ps-md-0 pt-md-5 datebox">
                        <div class="row justify-content-md-end starttime p-md-2 mt-4">
                            <div class="col-xl-1 col-md-1 col-sm-1 col-2">
                                <i class="fa fa-calendar icons text-white" aria-hidden="true"></i>
                            </div>
                            <div class="col-xl-2 col-md-3 col-sm-2 col-4 ps-1">
                                <h6 class="timedetails fsize-14 text-start text-white">Start Date</h6>
                                <span class="timevalue fsize-14 text-start text-white">{{
                                    proceedBookingObj.join.bookingData.date | date:'mediumDate'}}</span>
                            </div>
                            <div class="col-xl-1 col-md-1 col-sm-1 col-2 ms-3">
                                <i class="fa fa-clock-o icons text-white"></i>
                            </div>
                            <div class="col-xl-2 col-md-3 col-sm-2 col-4 ps-1">
                                <h6 class="timedetails fsize-14 text-start text-white"> Start Time</h6>
                                <span class="timevalue fsize-14 text-start text-white">{{proceedBookingObj.startTime }}
                                    hrs </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-0">
                    <div class="col-xl-12 px-3 px-md-0 py-5 details">
                        <h5 class="text-center fsize-16 my-auto  textnavy TTRegular-font"><b>WHAT HAPPENS NEXT?</b></h5>
                        <div class="row justify-content-center  pt-1 "
                            *ngFor="let singleEntry of proceedBookingObj?.whatHappensNext;let i=index;">
                            <div class="col-xl-6  col-lg-7  col-md-7 col-sm-10 ps-md-2 "
                                [ngClass]="proceedBookingObj?.whatHappensNext?.length==i+1?'mt-2':'my-2'">
                                <div class="row ps-md-0 my-1 TTRegular-font">
                                    <div class="col-lg-3 col-md-4 col-sm-4 my-auto col-5 py-2 duration text-center
                                        bg-white">
                                        <b><span class="mx-3 fsize-14 textnavy">{{singleEntry.time}}</span></b>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-7">
                                        <h6 class="ps-3 fsize-14 text-uppercase TTRegular-font textnavy">
                                            <b>{{singleEntry.title}}</b>
                                        </h6>
                                        <h6 class="ps-3 fsize-14 textnavy mb-0">{{singleEntry.description}} </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center inclusionbox paymentdeatil">
                        <div class="col-xl-6 col-lg-7 col-md-8 mb-3 ps-3 ps-md-2 px-3 px-md-0">
                            <div class="row py-3 ">
                                <div class="col-lg-8 col-md-8 col-7 inclusion">
                                    <h6 class="mt-3 fsize-16 text-uppercase TTRegular-font textnavy"> <b>Inclusions</b>
                                    </h6>
                                    <span *ngFor="let inc of proceedBookingObj.inclusion; let i = index">
                                        <div *ngIf="i%2 == 0;" class="fsize-14 textnavy">
                                            <div class="row">
                                                <div class="li">
                                                    <span class="py-3">
                                                        <img [src]="'/Tour-details/dos.png' | linkPrefix" alt=""></span>
                                                </div>
                                                <div class="col ps-2 TTRegular-font">
                                                    <span>{{inc}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-lg-3  col-5 totalguest">
                                    <h6 class="mt-3 fsize-16 text-uppercase TTRegular-font textnavy"><b>Total guests</b>
                                    </h6>
                                    <span class="fsize-14 textnavy TTRegular-font ">
                                        <span><i class="fa fa-user pe-1 fsize-18"></i></span>
                                        {{proceedBookingObj.join.bookingData.people.length}} <span
                                            *ngIf="proceedBookingObj.join.bookingData.people.length==1">Adult</span>
                                        <span *ngIf="proceedBookingObj.join.bookingData.people.length!=1">Adults</span>
                                    </span>
                                    <div class="fsize-14 textnavy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="payment"
                    class="row justify-content-center fsize-14 ps-2 py-3 discountBox my-4 paymentdeatil paymentbox">
                    <div class="col-xl-6 col-lg-7 col-md-8 ps-md-0 ps-1  py-md-2">
                        <div class="row pt-2 justify-content-center">
                            <div class="col-xl-9">
                                <span class="textnavy discounttext TTRegular-font"><i
                                        class="fa fa-tag ps-md-1 textnavy"></i> <b class="ps-1">Apply a discount code or
                                        a gift voucher</b></span>
                            </div>
                            <div class="col-xl-6 col-lg-7  col-9 ps-1 mb-1 mt-md-2 TTRegular-font">
                                <input type="" class="form-control shadow px-4  fw-bold p-2 mt-1"
                                    (keyup)="checkCouponCode($event)" placeholder="Enter coupon code here"
                                    [(ngModel)]="proceedBookingObj.promocode">
                            </div>
                            <div class="col-xl-3 col-lg-3  col-3  pt-0 pt-md-1 TTRegular-font">
                                <span><b class="rounded   discountbtn shadow px-3 py-2  ms-2 fsize-14 text-uppercase textnavy fw-bolder "
                                        (click)="applyCouponCode()">Apply </b></span>
                            </div>
                            <div class="col-xl-9 col-lg-10  ps-1 mt-2 TTRegular-font">
                                <span *ngIf="showCouponFailMsg" class="ps-md-1"> <i
                                        class="fa fa-warning fsize-18 text-danger"></i>
                                    <span class="ps-1 textnavy"><b>coupon code is invalid. Try with another one.</b>
                                    </span></span>
                                <span *ngIf="showCouponSuccessMsg" class="ps-md-1"> <i
                                        class="fa fa-check-circle fsize-18 text-success"></i>
                                    <span class="ps-1 textnavy"><b>coupon code is applied. </b></span></span>
                            </div>
                        </div>
                        <div class="row discount py-2 TTRegular-font">
                            <div class="col-xl-7 col-lg-7 col-md-6 col-6 ps-1">
                                <span class="float-start fsize-16 textnavy text-uppercase TTRegular-font">Base
                                    Fare</span>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-5 ms-2  text-end ">
                                <span class=" fsize-18 textnavy" [innerHTML]="proceedBookingObj?.join?.bookingData?.hs_cost
                                    | currencyConverter"></span>
                            </div>
                        </div>
                        <div class="row mt-2 discount py-2 TTRegular-font">
                            <div class="col-xl-7 col-lg-7 col-md-6 col-6 ps-1">
                                <span class="float-start fsize-16 textnavy text-uppercase TTRegular-font"> Discount
                                    applied</span>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-5 ms-2  text-end ">
                                <span class=" fsize-18 textnavy" [innerHTML]="proceedBookingObj.discountPrice
                                    | currencyConverter"></span>
                            </div>
                        </div>
                        <div class="row  mt-2 py-2 TTRegular-font">
                            <div class="col-xl-7 col-lg-7 col-md-6 col-6 ps-1">
                                <b class="float-start textnavy  fsize-16 text-uppercase TTRegular-font">Total Payable
                                </b>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-5 ms-2 text-end">
                                <b class="fsize-18 textnavy TTRegular-font">
                                    <span><span [innerHTML]="proceedBookingObj?.currency['symbol']"></span>
                                        {{convertPrice(proceedBookingObj?.join?.bookingData?.hs_cost)*(proceedBookingObj.join.bookingData?.people?.length
                                        | number)}} </span></b>
                            </div>
                        </div>
                        <div class="row  mt-3 justify-content-center TTRegular-font">
                            <div class="col-xl-12  ps-1">
                                <input type="checkbox" name="iagree" id="" [(ngModel)]="proceedBookingObj.join.iAgree"
                                    class="lblfont checkbox" />
                                <span class="ps-1 fsize-14 textnavy "> I agree to the <b><u class="textnavy"><a
                                                class="textnavy"
                                                href="https://www.strodeexperiences.com/#/policy/booking-policy">
                                                Booking Terms & Conditions</a> </u></b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class=" col-11 text-center">
                            <div class="row justify-content-center">
                                <div class=" col-9 text-center">
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class=" row justify-content-center paynowbox ">
            <div class="col-12 bg-white">
                <button class="pink-orangebtn border-0 red-btn py-3 dark-navy1 w-100  textnavy"
                    (mouseenter)="gotToPayment()" (click)="addBooking()">
                    <span class="btnshadow text-white TTRegular-font fsize-16 text-uppercase">Continue to Payment</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modelbox brochuremodel text-center position-relative">
    <p-dialog header="Select Payment Option" [(visible)]="proceedBookingObj.paymentModal" [modal]="true"
        [draggable]="false" [resizable]="false">
        <div class="text-center">
            <img class="mw-80 round-circle cursor-pointer" (click)="setPaymentOption('RazorPay')"
                [src]="'/booking/razorpay.webp' |linkPrefix" alt="">
        </div>
        <div class="text-center pb-3">
            <img class="mw-80 round-circle cursor-pointer" (click)="setPaymentOption('CashFree')"
                [src]="'/booking/cashfree.webp' |linkPrefix" alt="">
        </div>
    </p-dialog>
</div>
<p-toast></p-toast>