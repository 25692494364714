import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirstLetter',
})
export class capitalizeFirstLetter implements PipeTransform {
    temp: any;
    transform(paragraph: String): string {
        const words = paragraph.split(' ');
        const capitalizedWords = words.map(word => this.capitalizeFirstLetter(word));
        capitalizedWords.join(' ')
        return capitalizedWords.join(' ');
    }

    capitalizeFirstLetter(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
}
