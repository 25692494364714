import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/shared/services/common.service';
import { UtilityServiceService } from 'src/shared/services/utility-service.service';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss'],
})
export class PaymentFailComponent implements OnInit {
  constructor(private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }
  tryAgain() {
    // this.router.navigate(['/app-proceed-booking']);
    window.location.replace('https://www.strodeexperiences.com/#/booking/app-proceed-booking');
  }
}
