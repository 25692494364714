import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { LoaderComponent } from './loader/loader/loader.component';
import { AccordionModule } from 'primeng/accordion';
import { RatingModule } from 'primeng/rating';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { StarComponent } from './star/star.component';
import { SafePipe } from './pipes/safePipe';
import { ReplaceCamelCaseWithSpacesPipe } from './pipes/ReplaceCamelCaseWithSpacesPipe';
import { MinutesToHoursPipe } from './pipes/minutesToHours';
import { FooterComponent } from './footer/footer.component';
import { WebHeaderComponent } from './web-header/web-header.component';
import { SidebarModule } from 'primeng/sidebar';
import { capitalizeFirstLetter } from './pipes/capitalizeFirstLetter';
import { TourCarouselComponent } from './tour-carousel/tour-carousel.component';
import { DestinationBannerComponent } from './destination-banner/destination-banner.component';
import { TooltipModule } from 'primeng/tooltip';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { CurrencyConverterPipe } from './pipes/currencyConverterPipe';
import { RouterModule } from '@angular/router';
import { CurrencyConverterPipev2 } from './pipes/currencyConverterPipev2';
import { ExploreTourCarouselComponent } from './explore-tour-carousel/explore-tour-carousel.component';
import { LinkPrefixPipe } from './pipes/linkPrefixPipe';
const COMMON_COMPONENTS = [
  StarComponent,
  SafePipe,
  ReplaceCamelCaseWithSpacesPipe,
  MinutesToHoursPipe,
  FooterComponent,
  WebHeaderComponent,
  capitalizeFirstLetter,
  CurrencyConverterPipe,
  CurrencyConverterPipev2,
  LinkPrefixPipe
];
const PRIMENG_COMPONENTS = [
  CarouselModule,
  RatingModule,
  DialogModule,
  CalendarModule,
  InputNumberModule,
  RadioButtonModule,
  ToastModule,
  AccordionModule,
  DropdownModule,
  MenubarModule,
  SidebarModule,
  TooltipModule,
];

@NgModule({
  declarations: [COMMON_COMPONENTS, LoaderComponent, TourCarouselComponent, DestinationBannerComponent, ExploreTourCarouselComponent],
  imports: [CommonModule, PRIMENG_COMPONENTS, FormsModule, RouterModule],
  exports: [
    COMMON_COMPONENTS,
    PRIMENG_COMPONENTS,
    LoaderComponent,
    FooterComponent,
    WebHeaderComponent,
    TourCarouselComponent,
    ExploreTourCarouselComponent,
    DestinationBannerComponent
  ],
  providers: [MessageService],
})
export class SharedModule { }
