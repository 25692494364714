<div class="payment-fail">
    <div class="col-lg-6 text-center  position-absolute center-box">
        <div class="text-center ps-5">
            <img [src]="'/Tour-details/payment-fail.png' | linkPrefix" class="mw-300" alt="">
            <div class="position-relative">
                <div class="line-1 bg-violet position-absolute"></div>
                <div class="line-2 bg-violet position-absolute"></div>
            </div>
        </div>
        <h3 class="TTRegular-font font-50 mt-5"><b>Your payment has failed</b></h3>
        <p class="futura-book-font fsize-20 my-3">You can retry the payment below to continue <br> this</p>
        <br>
        <button class="common-button pink-orangebtn" (click)="tryAgain()">Try again</button>
    </div>
</div>