import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingRoutingModule } from './booking-routing.module';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { proceedBookingComponent } from './proceed-booking/proceed-booking.component';
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CheckPaymentStatusComponent } from './check-payment-status/check-payment-status.component';


@NgModule({
  declarations: [
    BookingConfirmationComponent,
    PaymentFailComponent,
    proceedBookingComponent,
    CheckPaymentStatusComponent
  ],
  imports: [
    CommonModule,
    BookingRoutingModule,
    SharedModule,
    FormsModule
  ]
})
export class BookingModule { }
