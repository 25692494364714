import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class SchemaService {
    tourList: any;
    renderer: any;
    constructor(@Inject(DOCUMENT) private dom: Document, private router: Router,
        private commonService: CommonService) {
    }

    loadSchema() {
        let path = this.router.url.split('/');
        if (path[1] == 'toursandactivities') {
            this.getUuidByTitle(path[2]);
        } else if (path[1].startsWith('things-to-do-in')) {
            this.loadProductSchema();
        } else if (path[2].startsWith('blog-detail')) {
            this.loadBlogSchema();
        } else {
            this.removeSchema();
        }
    }
    loadProductSchema() {
        let path = this.router.url.split('/');
        let temp = path.splice(-1).toString().split('-').splice(-1);
        let cityName = temp[0];
        if (cityName.includes('?')) {
            let city = temp[0].split('?')[0];
            cityName = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
        } else {
            cityName = cityName.charAt(0).toUpperCase() + cityName.slice(1).toLowerCase();
        }
        const payload = {
            page_number: 0,
            page_size: 100,
            city: cityName,
        };
        this.commonService.getTourList(payload).subscribe((res: any) => {
            if (res.code == 'SUCCESS' && res.message == 'GET_TOURS') {
                if (res.data.LIST.length > 0) {
                    let productSchemas = res.data.LIST.map((tour) => {
                        return {
                            '@context': 'https://schema.org/',
                            '@type': 'Product',
                            name: tour['name'],
                            description: tour['details'].sf_intro,
                            review: {
                                '@type': 'Review',
                                reviewRating: {
                                    '@type': 'Rating',
                                    ratingValue: tour['strode_rating'] | 4.5,
                                    bestRating: 5,
                                },
                                author: {
                                    '@type': 'Person',
                                    name: 'Strode Experience',
                                },
                            },
                        };
                    });
                    const script: HTMLScriptElement = this.dom.createElement('script');
                    script.setAttribute('type', 'application/ld+json');
                    script.innerText = JSON.stringify(productSchemas);
                    this.dom.head.appendChild(script);
                }
            }
        });
    }
    loadBlogSchema() {
        let path = this.router.url.split('/');
        let blogName = path[path.length - 1];
        this.commonService.getUuidByBlogTitle(blogName).subscribe((res: any) => {
            if (res.code == 'SUCCESS' && res.message == 'UUID') {
                this.commonService.getBlog(res.data).subscribe((res: any) => {
                    if (res.code == "SUCCESS" && res.message == 'BLOGS_LIST') {
                        const blogScript: HTMLScriptElement = this.dom.createElement('script');
                        blogScript.setAttribute('type', 'application/ld+json');
                        blogScript.innerText = JSON.stringify(res.data);
                        this.dom.head.appendChild(blogScript);
                    }
                })
            }
        })
    }
    removeSchema() {
        const existingCanonicalTags = this.dom.head.querySelectorAll('script[type="application/ld+json"]');
        existingCanonicalTags.forEach(tag => tag.remove());
    }
    getUuidByTitle(tourName) {
        this.commonService.getUuidByTitle(tourName).subscribe((res: any) => {
            if (res.code == 'SUCCESS' && res.message == 'UUID') {
                this.getTourDetail(res.data);
            }
        })
    }
    getTourDetail(tour_uuid) {
        this.commonService.getTourByIdV2(tour_uuid).subscribe((res: any) => {
            if (res.code == 'SUCCESS' && res.message == 'GET_TOUR') {
                this.loadFaqSchema(res.data);
                this.loadBreadcumbSchema(res.data);
                this.addProductSchema(res.data);
            }
        })
    }
    loadFaqSchema(data) {
        const jsonDataForFaq = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [],
        };

        data['details'].faq.forEach((faq) => {
            jsonDataForFaq.mainEntity.push({
                '@type': 'Question',
                name: faq.question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: faq.answer,
                },
            });
        });
        const faqScript: HTMLScriptElement = this.dom.createElement('script');
        faqScript.setAttribute('type', 'application/ld+json');
        faqScript.innerText = JSON.stringify(jsonDataForFaq);
        this.dom.head.appendChild(faqScript);
    }
    loadBreadcumbSchema(data) {
        let bcumbsData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://strodeexperiences.com/#/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": data['city'],
                "item": "https://strodeexperiences.com/#" + this.router.url
            }, {
                "@type": "ListItem",
                "position": 3,
                "name": "Travel Style"
            }]
        }
        const bcumbsScript: HTMLScriptElement = this.dom.createElement('script');
        bcumbsScript.setAttribute('type', 'application/ld+json');
        bcumbsScript.innerText = JSON.stringify(bcumbsData);
        this.dom.head.appendChild(bcumbsScript);
    }
    addProductSchema(data) {
        let productData = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "brand": {
                "@type": "Brand",
                "name": "Strode Experiences",
            },
            "description": data['details'].sf_intro,
            "image": "https://www.example.com/catcher-in-the-rye-book-cover.jpg",
            "name": data['name'],
            "review": [],
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5",
                "bestRating": "5",
                "ratingCount": "20"
            },
        }
        this.commonService.getTourReviews(data.tour_uuid).subscribe((res: any) => {
            if (res.code == 'SUCCESS') {
                productData.aggregateRating.ratingCount = res.data.length;
                res.data.forEach((ele) => {
                    let obj =
                    {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": ele["rating"] || 4.5
                        },
                        "author": {
                            "@type": "Person",
                            "name": ele['review_by'] || 'Strode Experience '
                        }
                    }
                    productData.review.push(obj);
                })
                const productScript: HTMLScriptElement = this.dom.createElement('script');
                productScript.setAttribute('type', 'application/ld+json');
                productScript.innerText = JSON.stringify(productData);
                this.dom.head.appendChild(productScript);
            }
        })

    }
}
