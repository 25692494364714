import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CanonicalService {
    constructor(@Inject(DOCUMENT) private dom: Document) {
    }

    createCanonicalURL() {
        // Remove existing canonical tags
        const existingCanonicalTags = this.dom.head.querySelectorAll('link[rel="canonical"]');
        existingCanonicalTags.forEach(tag => tag.remove());

        // Create and append the new canonical tag
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', this.dom.location.href);
        this.dom.head.appendChild(link);
    }
}
