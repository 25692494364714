import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private titleService: Title, private router: Router,
    @Inject(DOCUMENT) private dom,
    private metaTagService: Meta) { }
  setMetaInfo(title?: string, keywords?: string, description?: string, img?: string) {
    this.titleService.setTitle(`${title}`);
    this.metaTagService.updateTag({
      property: 'title',
      content: title,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: keywords.toString(),
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: description
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: description
    });
    this.metaTagService.updateTag({
      name: 'og:url',
      content: "https://strodeexperiences.com/#" + this.router.url,
    });
    if (img) {
      this.metaTagService.updateTag({ property: 'og:image:secure', content: img });
    }
  }
  setOgImage() {
    this.metaTagService.updateTag({ property: 'og:image:secure', content: "https://strode-media.s3.ap-south-1.amazonaws.com/strodeexperiences-live-media/home/logo_svg.png" });
    this.metaTagService.updateTag({
      name: 'og:url',
      content: "https://strodeexperiences.com/#" + this.router.url,
    });
  }
}
