<div class="container-fluid " id="web-header">
    <div class="row  p-0 py-0 header" [ngClass]="isFixedHeader?'fixed-header':''">
        <div class="col-md-2  ps-1   logobox">
            <div class="d-flex">
                <div class="d-flex col-lg-7 col-4">
                    <div class="col-5 ps-md-1 ps-0">
                        <a [routerLink]="['/']" class="text-decoration-none"><img
                                [src]="'/element/newlogo_IG7omY2v5.png' | linkPrefix" class="logo" alt=""></a>
                    </div>
                    <div class="col-7">
                        <a [routerLink]="['/']" class="text-decoration-none">
                            <h3 class="TTRegular-font fsize-16 text-white fw-bold py-4 logo-text">STRODE <br>
                                EXPERIENCES </h3>
                        </a>
                    </div>
                </div>
                <div class="text-center">
                    <button (click)="exploremore($event)"
                        class="white-pinkbtn text-uppercase common-button  position-absolute border-0 round-30 text-dark explore-morebtn">Explore
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-8 ps-md-4 pe-md-5 col-12 text-center px-0 my-auto ">
            <div class="px-md-4">
                <header>
                    <div id="menu_button" class="cursor-pointer" (click)="openMenu()">
                        <a id="menulink">
                            <span class="top_bar"></span>
                            <span class="middle_bar"></span>
                            <span class="bottom_bar"></span>
                        </a>
                    </div>
                    <p-accordion id="mobile-menu" [multiple]="false">
                        <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(0)" [(selected)]="accordionActiveState[0]">
                                <ng-template pTemplate="header">
                                    <div class="text-center w-100 text-white"> WHAT IS STRODE </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ul>
                                        <li><a [routerLink]="['/what-is-strode/how-we-work']" s
                                                class="nav_about text-white">HOW WE WORK</a>
                                        </li>
                                        <li><a [routerLink]="['/what-is-strode/what-we-do']"
                                                class="nav_about text-white">WHAT WE DO</a></li>
                                        <li><a [routerLink]="['/what-is-strode/sustainability']"
                                                class="nav_about text-white">SUSTAINABILITY</a>
                                        </li>
                                        <li><a [routerLink]="['/what-is-strode/self-guided-trips']"
                                                class="nav_about text-white">SELF GUIDED TRIPS</a>
                                        </li>
                                        <li><a [routerLink]="['/what-is-strode/blogs']"
                                                class="nav_about text-white">BLOGS</a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </p-accordionTab>
                        </div>
                        <p-accordionTab (click)="toggleAccordion(1)" [(selected)]="accordionActiveState[1]">
                            <ng-template pTemplate="header">
                                <div class="text-center w-100 text-white"> DESTINATIONS </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <ul>
                                    <li><a (click)="redirectToCity('lonavala')"
                                            class="nav_about text-white">LONAVALA</a></li>
                                    <li><a (click)="redirectToCity('jaipur')" class="nav_about text-white">JAIPUR</a>
                                    </li>
                                    <li><a (click)="redirectToCity('udaipur')" class="nav_about text-white">UDAIPUR</a>
                                    </li>
                                    <li><a (click)="redirectToCity('amsterdam')"
                                            class="nav_about text-white">AMSTERDAM</a></li>
                                </ul>
                            </ng-template>
                        </p-accordionTab>
                        <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(2)" [(selected)]="accordionActiveState[2]">
                                <ng-template pTemplate="header">
                                    <div class="text-center w-100 text-white"> ALL THE INFO </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ul>
                                        <li><a [routerLink]="['/all-the-info/our-travel-style']" s
                                                class="nav_about text-white">OUR TRAVEL STYLES</a></li>
                                        <li><a [routerLink]="['/all-the-info/join-our-team']"
                                                class="nav_about text-white">JOIN OUR TEAM</a>
                                        </li>
                                        <li><a [routerLink]="['/other/contact-us']"
                                                class="nav_about TTRegular-font fsize-17 text-white">CONTACT US</a></li>
                                    </ul>
                                </ng-template>
                            </p-accordionTab>
                        </div>
                        <!-- <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(3)" [(selected)]="accordionActiveState[3]">
                                <ng-template pTemplate="header">
                                    <div class="currency-dropdown">
                                        <p-dropdown [options]="currency" [(ngModel)]="selectedCurrency"
                                            (onChange)="changeCurrency(selectedCurrency)" optionLabel="type">
                                        </p-dropdown>
                                    </div>
                                </ng-template>
                            </p-accordionTab>
                        </div> -->
                    </p-accordion>
                    <ul id="navlist" class="mob-display-none">
                        <li class="submenu"><a class="nav_about  TTRegular-font fsize-17 ">WHAT IS STRODE</a>
                            <ul>
                                <li [ngClass]="path=='/what-is-strode/how-we-work'?'active':''"><a
                                        [routerLink]="['/what-is-strode/how-we-work']"
                                        class="nav_about TTRegular-font fsize-17">HOW WE WORK</a></li>
                                <li [ngClass]="path=='/what-is-strode/what-we-do'?'active':''"><a
                                        [routerLink]="['/what-is-strode/what-we-do']"
                                        class="nav_about TTRegular-font fsize-17">WHAT WE DO</a></li>
                                <li [ngClass]="path=='/what-is-strode/sustainability'?'active':''"> <a
                                        [routerLink]="['/what-is-strode/sustainability']"
                                        class="nav_about TTRegular-font fsize-17">SUSTAINABILITY</a>
                                </li>
                                <li [ngClass]="path=='/what-is-strode/self-guided-trips'?'active':''"><a
                                        [routerLink]="['/what-is-strode/self-guided-trips']"
                                        class="nav_about TTRegular-font fsize-17">SELF GUIDED TRIPS</a>
                                </li>
                                <li [ngClass]="path=='/what-is-strode/blogs'?'active':''"><a
                                        [routerLink]="['/what-is-strode/blogs']"
                                        class="nav_about TTRegular-font fsize-17">BLOGS</a>
                                </li>
                            </ul>
                        </li>
                        <li class="submenu"><a class="nav_about TTRegular-font fsize-17">DESTINATIONS</a>
                            <ul>
                                <li [ngClass]="path=='/things-to-do-in-lonavala'?'active':''"><a
                                        (click)="redirectToCity('lonavala')"
                                        class="nav_about TTRegular-font fsize-17">LONAVALA</a></li>
                                <li [ngClass]="path=='/things-to-do-in-jaipur'?'active':''"><a
                                        (click)="redirectToCity('jaipur')"
                                        class="nav_about TTRegular-font fsize-17">JAIPUR</a></li>
                                <li [ngClass]="path=='/things-to-do-in-udaipur'?'active':''"><a
                                        (click)="redirectToCity('udaipur')"
                                        class="nav_about TTRegular-font fsize-17">UDAIPUR</a></li>
                                <li [ngClass]="path=='/things-to-do-in-amsterdam'?'active':''"><a
                                        (click)="redirectToCity('amsterdam')"
                                        class="nav_about TTRegular-font fsize-17">AMSTERDAM</a></li>
                            </ul>
                        </li>
                        <li class="submenu"><a class="nav_about TTRegular-font fsize-17">ALL THE INFO</a>
                            <ul>
                                <li [ngClass]="path=='/all-the-info/our-travel-style'?'active':''"><a
                                        [routerLink]="['/all-the-info/our-travel-style']"
                                        class="nav_about TTRegular-font fsize-17">OUR TRAVEL STYLES</a></li>
                                <li [ngClass]="path=='/all-the-info/join-our-team'?'active':''"><a
                                        [routerLink]="['/all-the-info/join-our-team']"
                                        class="nav_about TTRegular-font fsize-17">JOIN OUR TEAM</a>
                                </li>
                                <li [ngClass]="path=='/other/contact-us'?'active':''"><a
                                        [routerLink]="['/other/contact-us']"
                                        class="nav_about TTRegular-font fsize-17">CONTACT US</a></li>
                            </ul>
                        </li>
                        <li>
                            <div class="position-absolute currency-dropdown ">
                                <p-dropdown [options]="currency" [(ngModel)]="selectedCurrency"
                                    (onChange)="changeCurrency(selectedCurrency)" optionLabel="type">
                                </p-dropdown>
                            </div>
                        </li>
                        <li>
                            <button (click)="exploremore($event)" (mouseenter)="exploreText = 'Explore'"
                                (mouseleave)="exploreText = 'Explore'"
                                class="white-pinkbtn common-button position-absolute text-dark border-0 round-30 explore-morebtn">
                                {{exploreText}} </button>
                        </li>
                    </ul>
                </header>
            </div>
        </div>
    </div>
</div>