import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { WindowRef } from 'src/shared/services/WindowRef.service';
import { SidebarModule } from 'primeng/sidebar';
import { DefaultUrlSerializer, RouteReuseStrategy } from '@angular/router';
import { DefaultRouteReuseStrategy } from 'src/shared/DefaultRouteReuseStrategy';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ScriptLoaderService } from 'src/shared/services/script-loader.service';
import { ExploreNeighbourhoodsComponent } from './destination/explore-neighbourhoods/explore-neighbourhoods.component';
import { NeighbourhoodDetailsComponent } from './destination/neighbourhood-details/neighbourhood-details.component';
import { GlobalErrorHandler } from './GlobalErrorHandler';

@NgModule({
  declarations: [AppComponent, DashboardComponent, ExploreNeighbourhoodsComponent, NeighbourhoodDetailsComponent],
  providers: [
    WindowRef,
    {
      provide: RouteReuseStrategy,
      useClass: DefaultRouteReuseStrategy,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ScriptLoaderService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    SidebarModule,
  ],
})
export class AppModule { }
